import https from "./https"
import store from "@/store"

const guard = {
  getGuardGroups(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/guard-groups`, { params })
  },
  getOAGroups() {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/oa-groups`)
  },
  setOAGroup(guardGroup, params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/guard-groups/${guardGroup}`, params)
  },
  getActiveGuardGroups() {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/active-guard-groups`)
  },
  getProtectOfGroup(guardGroup) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/guard-groups/${guardGroup}/protect`)
  },
  setProtectOfGroup(guardGroup, params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/guard-groups/${guardGroup}/protect`, params)
  },
  getCustomersOfGuardGroup(guardGroup, params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/guard-groups/${guardGroup}/customers`, { params })
  },
  getGlobalBlackCustomersOfGuardGroup(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/guard-groups/global-black-customers`, { params })
  },
  setRoleToCustomers(guardGroup, params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/guard-groups/${guardGroup}/customers-role`, params)
  },
  checkBeforeSetRoleToCustomer(guardGroup, params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/guard-groups/${guardGroup}/check-before-set-customers-role`, { params })
  },
  setRoleToGlobalBlackCustomers(guardGroup, params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/guard-groups/global-black-customers/customers-role`, params)
  },
  getGuardStatus(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/guard-groups/status`, { params })
  },
  getGuardDetail(customer) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/guards/${customer}`)
  },
  getGroupsOfGuard(customer) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/guards/${customer}/groups`)
  },
  updateGuardReport(guardReportId, params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/guard-reports/${guardReportId}`, params)
  }
}

export default guard
