<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'GuardGroups' }"
              >群組保護</b-breadcrumb-item
            >
            <b-breadcrumb-item active>衛兵群組列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">衛兵群組列表</h4>

        <div class="row d-flex justify-content-between align-items-center my-3">
          <div class="col-12 col-xl-6 mb-2 mb-xl-0 d-flex align-items-center">
            <h6 class="font-weight-bold flex-shrink-0 mb-0 mr-2">群組狀態</h6>
            <AppMultiSelect
              v-model="selectedStatus"
              :options="statusOptions"
              @input="fetchGuardGroupsFirstPage"
            />
          </div>
          <div
            class="
              col-12 col-xl-6
              d-flex
              flex-column flex-xl-row
              align-items-start align-items-xl-center
            "
          >
            <b-input-group class="ml-0 ml-xl-4">
              <b-form-input
                placeholder="請輸入衛兵群組名稱"
                v-model="keyword"
                @keyup.enter="fetchGuardGroupsFirstPage()"
              ></b-form-input>
              <b-input-group-append>
                <b-button @click="fetchGuardGroupsFirstPage()"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <hr />
        <div class="font-weight-bold">群組數目：{{ groups.length }} 個群組</div>

        <b-table
          responsive
          striped
          hover
          :items="groups"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #head(count_guard_group)="">
            <div>衛兵回報<br>人數</div>
          </template>
          <template #head(count_oa_group)="">
            <div>OA回報<br>人數</div>
          </template>
          <template #head(count_admin)="">
            <div>管理員<br>人數</div>
          </template>
          <template #head(count_healthy_guard)="">
            <div>連線<br>衛兵數</div>
          </template>

          <template #cell(name)="data">
            <div class="mb-1">
              {{ data.item.name }}
            </div>
            <div>
              {{ data.item.group_number }}
            </div>
          </template>
          <template #cell(picture_url)="data">
            <b-img-lazy
              v-bind="mainProps"
              v-show="!!data.item.picture_url"
              rounded
              :src="data.item.picture_url"
            ></b-img-lazy>
          </template>
          <template #cell(status)="data">
            <i
              :class="`fa fa-circle h2 text-${statusEnum(data.item.status)}`"
            ></i>
          </template>

          <template #cell(team)="data">
            <!-- 因為欄位有可能對 team 排序，如果在修改狀態下修改 item.team 會造成排序變動，應該要等「確定更新」才重新排序 -->
            <!-- 所以多一個 editTeam 做中間的緩衝，當修改 editTeam 並不會造成排序的變動 -->
            <b-form-input v-if="data.item.status != 0" type="text" v-model="data.item.team" readonly></b-form-input>
            <b-form-input v-else type="text" v-model="editTeam"></b-form-input>
          </template>

          <template #cell(oa)="data">
            <b-form-select
              v-if="data.item.status == 0"
              v-model="data.item.oa_group_id"
              :options="oaGroups"
            >
              <template #first>
                <b-form-select-option value=""
                  >-- 請選擇 --</b-form-select-option
                >
              </template>
            </b-form-select>
            <b-form-select v-else disabled>
              <b-form-select-option v-if="data.item.status == 2"
                >黑名單</b-form-select-option
              >
              <b-form-select-option v-else>{{
                data.item.oa_group_name
              }}</b-form-select-option>
            </b-form-select>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-if="data.item.status == 0"
              variant="primary"
              class="ml-2"
              size="md"
              :disabled="
                !data.item.oa_group_name && data.item.oa_group_id == ''
              "
              @click="
                () => {
                  setOAGroup(data.item);
                }
              "
              >{{ data.item.oa_group_name ? "確定更新" : "設定" }}</b-button
            >

            <b-button
              v-if="data.item.status != 0"
              variant="danger"
              class="ml-2"
              size="md"
              @click="handleReset(data.item)"
              >重設</b-button
            >
            <!-- <b-button
              variant="danger"
              class="ml-2"
              size="md"
              @click="
                () => {
                  resetOAGroup(data.item.id);
                }
              "
              >Reset</b-button
            > -->
          </template>
        </b-table>
      </b-overlay>
      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import guardApi from "@/apis/guard";
import RoleChecker from "@/utils/RoleChecker";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import AppMultiSelect from "@/components/AppMultiSelect";

export default {
  components: {
    AppMultiSelect,
  },
  data() {
    return {
      sortBy: "name",
      sortDesc: false,
      consts,
      showLoading: true,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1",
      },
      fields: [
        {
          key: "status",
          label: "狀態",
        },
        {
          key: "picture_url",
          label: "照片",
        },
        {
          key: "name",
          label: "衛兵群組名稱/編號",
        },
        {
          key: "count_guard_group",
          label: "衛兵回報人數",
          sortable: true,
        },
        {
          key: "count_oa_group",
          label: "OA回報人數",
          sortable: true,
        },
        {
          key: "count_admin",
          label: "管理員人數",
          sortable: true,
        },
        {
          key: "count_guard",
          label: "衛兵數",
          sortable: true,
        },
        {
          key: "count_healthy_guard",
          label: "連線衛兵數",
          sortable: true,
        },
        {
          key: "team",
          label: "Team",
          sortable: true,
        },
        {
          key: "oa",
          label: "OA 群組名稱/編號",
        },
        { key: "actions", label: "功能" },
      ],
      groups: [],
      oaGroups: [],
      currentGroup: {},
      updateGroup: {},
      editTeam: null,
      statusOptions: [
        { text: "已設定", value: "1" },
        { text: "未設定", value: "0" },
        { text: "黑名單", value: "2" },
      ],
      selectedStatus: [],
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      keyword: null,
    };
  },
  mounted() {
    this.selectedStatus =
      this.statusOptions.length > 0 ? [this.statusOptions[0]] : [];
    this.fetchGuardGroups();
    this.fetchOAGroups();
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  methods: {
    async fetchGuardGroups() {
      this.showLoading = true;

      try {
        const params = {};
        if (this.keyword) params.keyword = this.keyword;

        params.status = this.selectedStatus.map(({ value }) => value);

        const { data } = await guardApi.getGuardGroups(params);

        this.groups = data.data;
        this.totalRows = data.data.length;
      } catch (error) {
        console.error(error);
        alert("取得群組資料錯誤");
      }
      this.showLoading = false;
    },
    fetchGuardGroupsFirstPage() {
      this.currentPage = 1
      this.fetchGuardGroups()
    },
    async fetchOAGroups() {
      try {
        const { data } = await guardApi.getOAGroups();

        this.oaGroups = [
          {
            text: "黑名單",
            value: "2",
          },
        ].concat(
          data.data.map((x) => {
            return {
              text: `${x?.branch?.branch_code ?? ""} ${x.name}`,
              value: x.id,
            };
          })
        );
      } catch (error) {
        console.error(error);
        alert("取得OA群組資料錯誤");
      }
    },
    async resetOAGroup(guardGroup) {
      try {
        let response = await guardApi.setOAGroup(guardGroup, {
          status: 0,
        });

        if (response.status !== 200) {
          alert("更新OA群組錯誤");
        } else {
          await this.fetchGuardGroups();
          await this.fetchOAGroups();
        }
      } catch (error) {
        alert("更新OA群組錯誤");
      }
    },
    handleReset(item) {
      if (item.status == 1) {
        this.oaGroups.push({
          text: item.oa_group_name,
          value: item.oa_group_id,
        });
      } else if (item.status == 2) {
        item.oa_group_id = '';
      }

      item.status = 0;
      this.editTeam = item.team
    },
    async setOAGroup(item) {
      try {
        let params = item.oa_group_id == "2"
            ? {
                status: 2,
              }
            : item.oa_group_id == ""
            ? {
                status: 0,
              }
            : {
                group_id: item.oa_group_id,
                status: 1,
              }
        params.team = this.editTeam

        let response = await guardApi.setOAGroup(
          item.id,
          params
        );

        if (response.status !== 200) {
          alert("更新OA群組錯誤");
        } else {
          await this.fetchGuardGroups();
          //item.status=1;
          //item.oa_group_id=item.
          await this.fetchOAGroups();
        }
      } catch (error) {
        alert("更新OA群組錯誤");
      }
    },

    statusEnum(status) {
      if (status === 0) {
        return "danger";
      }
      if (status === 1) {
        return "success";
      }
      if (status === 2) {
        return "dark";
      }
    },
    checkRole(roles) {
      const checker = new RoleChecker();
      return checker.check(roles);
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
