import store from '@/store'

export default class RoleChecker {
    check = (roles) => {
      const currentRole = store.getters['general/role']

      if (roles.find(role => role === currentRole)) {
        return true
      }

       return false
    }
}
