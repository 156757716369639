<template>
  <Multiselect
    v-model="selected"
    :options="options"
    :label="label"
    multiple
    track-by="value"
    @input="handleSelect"
    placeholder="輸入查詢"
    selectLabel="請選擇"
    selectedLabel="（已勾選）"
    deselectLabel="取消勾選"
  >
    <span slot="noOptions">
      <div>查無資料</div>
    </span>
  </Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  props: {
    value: Array,
    options: Array,
    label: {
      type: String,
      default: "text",
    },
  },
  components: {
    Multiselect,
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set() {
        // this.$emit("input", v);
      },
    },
  },
  methods: {
    handleSelect(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style></style>
